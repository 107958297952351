
import { defineComponent, computed } from 'vue'
import FormInput from '@/components/organisms/dms/common/FormInput.vue'

export default defineComponent({
  name: 'OthersForm',
  components: {
    FormInput,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    return {
      props,
      state,
    }
  },
})
