
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ItemForm from '@/components/organisms/dms/OnlineInput/ItemForm.vue'
import UrlForm from '@/components/organisms/dms/OnlineInput/UrlForm.vue'
import CollectInfoForm from '@/components/organisms/dms/OnlineInput/CollectInfoForm.vue'
import PermissionForm from '@/components/organisms/dms/OnlineInput/PermissionForm.vue'
import UserDataForm from '@/components/organisms/dms/OnlineInput/UserDataForm.vue'
import OthersForm from '@/components/organisms/dms/OnlineInput/OthersForm.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { AutoCollectApplicant, AutoCollectOnlineData, AutoCollectUrl, AutoCollectForm } from '@/data/dms/dmsData'

export default defineComponent({
  name: 'OnlineInput',
  components: {
    ItemForm,
    UrlForm,
    CollectInfoForm,
    PermissionForm,
    UserDataForm,
    OthersForm,
    DssIcon,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const agreementFlag = route.params.agreement === 'true'
    const requiredCheck = ref(false)
    const mailAddressCheck = ref('')
    const autoCollectOnlineData = store.getters.autoCollectData.autoCollectOnlineData ? ref(store.getters.autoCollectData.autoCollectOnlineData) : ref<AutoCollectOnlineData>({
      name: '',
      edition: '',
      volume: '',
      author: '',
      publisher: '',
      publishYear: '西暦',
      publishFrom: '',
      publishTo: '',
      codeType: 'コード',
      code: '',
    })
    const autoCollectUrl = store.getters.autoCollectData.autoCollectUrl ? ref(store.getters.autoCollectData.autoCollectUrl) : ref<AutoCollectUrl>({
      pageUrl: '',
      fileUrl: '',
    })
    const regularlyCollect = store.getters.autoCollectData.regularlyCollect ? ref(store.getters.autoCollectData.regularlyCollect) : ref<string>('国立国会図書館による定期収集を希望する')
    const permission = store.getters.autoCollectData.permission ? ref(store.getters.autoCollectData.permission) : ref<string>('')
    const autoCollectApplicant = store.getters.autoCollectData.autoCollectApplicant ? ref(store.getters.autoCollectData.autoCollectApplicant) : ref<AutoCollectApplicant>({
      name: '',
      institution: '',
      mailAddress: '',
      mailAddressCheck: '',
      relation: '',
    })
    const others = store.getters.autoCollectData.others ? ref(store.getters.autoCollectData.others) : ref<string>('')

    const setAutoCollectData = async (autoCollectData: AutoCollectForm) => {
      await store.dispatch('setAutoCollectData', autoCollectData)
    }
    const packAutoCollectDataData = async () => {
      const autoCollectData = {
        autoCollectApplicant: autoCollectApplicant.value,
        autoCollectOnlineData: autoCollectOnlineData.value,
        autoCollectUrl: autoCollectUrl.value,
        others: others.value,
        regularlyCollect: regularlyCollect.value,
        permission: permission.value,
      } as AutoCollectForm
      await setAutoCollectData(autoCollectData)
    }
    onMounted(async () => {
      if (!agreementFlag) {
        await router.push('/dms/online/agreement')
      }
    })
    return {
      router,
      autoCollectOnlineData,
      autoCollectUrl,
      regularlyCollect,
      permission,
      autoCollectApplicant,
      others,
      requiredCheck,
      mailAddressCheck,

      async confirm () {
        requiredCheck.value = true
        await window.fetch('')
        const elements = document.getElementsByClassName('text-alert')
        if (elements.length !== 0) {
          window.scrollTo({
            left: elements[0].getBoundingClientRect().left,
            top: elements[0].getBoundingClientRect().top,
            behavior: 'smooth',
          })
          return
        }
        await packAutoCollectDataData()
        await router.push('confirm')
      },
      inputClear () {
        autoCollectOnlineData.value = {
          name: '',
          edition: '',
          volume: '',
          author: '',
          publisher: '',
          publishYear: '西暦',
          publishFrom: '',
          publishTo: '',
          codeType: 'コード',
          code: '',
        }
        autoCollectUrl.value = {
          pageUrl: '',
          fileUrl: '',
        }
        regularlyCollect.value = '国立国会図書館による定期収集を希望する'
        permission.value = '国立国会図書館内限定で公開する'
        autoCollectApplicant.value = {
          name: '',
          institution: '',
          mailAddress: '',
          mailAddressCheck: '',
          relation: '',
        }
        others.value = ''
      },
    }
  },
})
