
import { defineComponent, PropType, computed } from 'vue'
import FormInput from '@/components/organisms/dms/common/FormInput.vue'
import { AutoCollectUrl } from '@/data/dms/dmsData'
import DssIcon from '@/components/atoms/DssIcon.vue'

export default defineComponent({
  name: 'UrlForm',
  components: {
    FormInput,
    DssIcon,
  },
  props: {
    modelValue: {
      type: Object as PropType<AutoCollectUrl>,
    },
    requiredCheck: {
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const baseUrl = process.env.VUE_APP_BASE_URL
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    return {
      props,
      state,
      baseUrl,
    }
  },
})
