
import { defineComponent, PropType, ref, computed } from 'vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import InputDateDms from '@/components/organisms/dms/common/InputDateDms.vue'
import FormInput from '@/components/organisms/dms/common/FormInput.vue'
import { AutoCollectOnlineData } from '@/data/dms/dmsData'

export default defineComponent({
  name: 'ItemForm',
  components: {
    AppInput,
    AppSelect,
    TextAlert,
    InputDateDms,
    FormInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<AutoCollectOnlineData>,
    },
    requiredCheck: {
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    const blurFlag = ref(false)
    state.value!.publishYear = state.value!.publishYear ? state.value!.publishYear : '西暦'
    state.value!.codeType = state.value!.codeType ? state.value!.codeType : 'コード'
    const publishYearList = [
      { name: '西暦', value: '西暦' },
      { name: '明治', value: '明治' },
      { name: '大正', value: '大正' },
      { name: '昭和', value: '昭和' },
      { name: '平成', value: '平成' },
      { name: '令和', value: '令和' },
    ]
    const codeTypeList = [
      { name: 'コード', value: 'コード' },
      { name: 'ISBN', value: 'ISBN' },
      { name: 'ISSN', value: 'ISSN' },
      { name: 'DOI', value: 'DOI' },
    ]
    return {
      props,
      state,
      publishYearList,
      codeTypeList,
      blurFlag,

      blur () {
        blurFlag.value = true
      },
    }
  },
})
