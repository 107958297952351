
import { defineComponent, computed } from 'vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'

export default defineComponent({
  name: 'CollectInfoForm',
  components: {
    RadioButtonList,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (selectedValue) =>
        emit('update:modelValue', selectedValue),
    })
    selectedValue.value = selectedValue.value ? selectedValue.value : '国立国会図書館による定期収集を希望する'
    const radioList = [
      { value: '国立国会図書館による定期収集を希望する', label: '国立国会図書館による定期収集を希望する' },
      { value: '国立国会図書館による定期収集を希望しない', label: '国立国会図書館による定期収集を希望しない' },
      { value: '資料は継続出版しない', label: '資料は継続出版しない' },
    ]
    return {
      props,
      selectedValue,
      radioList,
    }
  },
})
