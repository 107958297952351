
import { defineComponent, ref, computed } from 'vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppTextarea from '@/components/atoms/AppTextarea.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'

export default defineComponent({
  name: 'FormInput',
  components: {
    AppInput,
    AppTextarea,
    TextAlert,
  },
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '220px',
    },
    requiredCheck: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    const blurFlag = ref(false)
    const emailCheck = computed(() => {
      if (props.type === 'email' && !state.value?.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) {
        return true
      } else {
        return false
      }
    })
    return {
      props,
      state,
      blurFlag,
      emailCheck,
    }
  },
})
