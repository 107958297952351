
import { defineComponent, PropType, computed, ref } from 'vue'
import { AutoCollectApplicant } from '@/data/dms/dmsData'
import FormInput from '@/components/organisms/dms/common/FormInput.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'

export default defineComponent({
  name: 'UserDataForm',
  components: {
    FormInput,
    AppInput,
    TextAlert,
    DssIcon,
  },
  props: {
    modelValue: {
      type: Object as PropType<AutoCollectApplicant>,
    },
    requiredCheck: {
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    const blurFlag = ref(false)
    const mailAddressErrFlag = computed(() => {
      return !!((state.value?.mailAddress !== state.value?.mailAddressCheck) && (state.value?.mailAddress && state.value?.mailAddressCheck))
    })
    const emailCheck = computed(() => {
      if (!state.value?.mailAddressCheck?.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) {
        return true
      } else {
        return false
      }
    })
    return {
      props,
      state,
      mailAddressErrFlag,
      blurFlag,
      emailCheck,
    }
  },
})
