
import { defineComponent, computed } from 'vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'

export default defineComponent({
  name: 'PermissionForm',
  components: {
    RadioButtonList,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (selectedValue) =>
        emit('update:modelValue', selectedValue),
    })
    selectedValue.value = selectedValue.value ? selectedValue.value : '国立国会図書館内限定で公開する'
    const radioList = [
      { value: '国立国会図書館内限定で公開する', label: '国立国会図書館内限定で公開する' },
      { value: '国立国会図書館によるインターネット公開を許諾する', label: '国立国会図書館によるインターネット公開を許諾する' },
    ]
    return {
      props,
      selectedValue,
      radioList,
    }
  },
})
