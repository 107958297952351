
import { defineComponent, reactive, watch, computed } from 'vue'
import { StyleName, styleNames } from '../../../../data/def'
import AppInput from '../../../atoms/AppInput.vue'

export default defineComponent({
  components: {
    AppInput,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
  },
  setup (props, context) {
    const date = computed({
      get: () => props.modelValue,
      set: (date) =>
        context.emit('update:modelValue', date),
    })
    const state = reactive({
      y: props.modelValue.includes('年') ? props.modelValue.split('年')[0] : '',
      m: props.modelValue.includes('月') ? props.modelValue.split('月')[0].slice(-2) : '',
      d: props.modelValue.includes('日') ? props.modelValue.split('日')[0].slice(-2) : '',
    })

    watch(date, () => {
      state.y = props.modelValue.includes('年') ? props.modelValue.split('年')[0] : ''
      state.m = props.modelValue.includes('月') ? props.modelValue.split('月')[0].slice(-2) : ''
      state.d = props.modelValue.includes('日') ? props.modelValue.split('日')[0].slice(-2) : ''
    })

    watch(state, () => {
      const y = state.y ? state.y + '年' : state.y
      const m = state.m ? ('00' + state.m).slice(-2) + '月' : state.m
      const d = state.d ? ('00' + state.d).slice(-2) + '日' : state.d
      date.value = `${y}${m}${d}`
    })

    return {
      context,
      state,
      date,
      props,
    }
  },
})
